body {
  background-color: #282c34;
  /* background-image: linear-gradient(black, #283134, #00b4ff, lightblue, #00b4ff, #283134, black); */
  /* background-image: linear-gradient(#0b0b0b, #802900, #ff5100, #ffadad, #ff5100, #802900, #0b0b0b); */
}

.App {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}
.App-header {
  color: whitesmoke;
  text-shadow: 6px 4px 4px black;
  font-size: calc(.5em + 2vmin);
  margin-bottom: 1.4em;
}
.mainContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* align-self: flex-start; */
  width: 100%;
  padding-top: 3vh;
}
@media (min-width: 950px) {
  .mainContent {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.selection {
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  user-select: none;
  outline: none;
}

input:focus {
  outline: none;
}

/* footer {
  margin-top: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	color: whitesmoke;
	background-image: linear-gradient(to bottom left, #00c0ff, #1b67da);
	height: 40px;
	width: 100%;
} */