.modalOuterContainer {
	position: absolute;
	align-self: flex-end;
	top: 20px;
	right: 20px;
}

.openModalButton {
	background: whitesmoke;
	height: 3em;
	border: initial;
	border-radius: 6px;
	font-weight: 700;
	cursor: pointer;
}

.closeModalButton {
	float: right;
	font-size: 62px;
	cursor: pointer;
}

/* * Overlay */
.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 1000ms ease-in-out;
	height: 100%;
	width: 100%;
	overflow: scroll;

}

.ReactModal__Overlay--after-open{
	opacity: 1;
}

.ReactModal__Overlay--before-close{
	opacity: 0;
}

/* * Content */
.ReadModal__Content {
	text-align: center;
	overflow-x: hidden;
}

.modalInput {
	background-color: red;
	text-align: center;
}

/* disallow scrolling on body when modal is up */
.ReactModal__Body--open {
	overflow: hidden;
}

.modal-content h1 {
	text-align: center;
}

.modal-content__image {
	width: 90%;
	margin: 0 auto;
}