.keyWrapper {
	margin-left: .2em;
	margin-right: .2em;
}
label.keyLabel {
	font-size: 0.9em;
	border-radius: 50%;
	background-image: radial-gradient(at 65% 25%, #00c0ff 0%, #0085b1 80%);
	display: inline-block;
	cursor: pointer;
	line-height: 1.7;
	color: #1d1d1d;
	height: calc(1.4em + 1vw);
	width: calc(1.4em + 1vw);
}
@media (min-width: 400px) {
	label.keyLabel {
		height: calc(1.7em + 1vw);
		width: calc(1.7em + 1vw);
	}
}

@media (min-width: 500px) {
	label.keyLabel {
		height: calc(2em + 1vw);
		width: calc(2em + 1vw);
		line-height: 2.4;
	}
}
@media (min-width: 950px) {
	label.keyLabel {
		height: calc(2em + 1vw);
		width: calc(2em + 1vw);
		line-height: 3.2;
		margin: 0.2em;
	}
}

input:checked + label.keyLabel {
	background-image: radial-gradient(#00c0ff 0%, #e5dede 92%);
}

input {
	position: absolute;
  left: -1000px;
}
