.keyContainerWrapper {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 1.4em;
}
@media (min-width: 950px) {
	.keyContainerWrapper {
		flex-direction: column;
	}
}
.sharpFlatContainer {
	display: flex;
	flex-direction: row;
}
