body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.keyWrapper {
	margin-left: .2em;
	margin-right: .2em;
}
label.keyLabel {
	font-size: 0.9em;
	border-radius: 50%;
	background-image: -webkit-radial-gradient(at 65% 25%, #00c0ff 0%, #0085b1 80%);
	background-image: radial-gradient(at 65% 25%, #00c0ff 0%, #0085b1 80%);
	display: inline-block;
	cursor: pointer;
	line-height: 1.7;
	color: #1d1d1d;
	height: calc(1.4em + 1vw);
	width: calc(1.4em + 1vw);
}
@media (min-width: 400px) {
	label.keyLabel {
		height: calc(1.7em + 1vw);
		width: calc(1.7em + 1vw);
	}
}

@media (min-width: 500px) {
	label.keyLabel {
		height: calc(2em + 1vw);
		width: calc(2em + 1vw);
		line-height: 2.4;
	}
}
@media (min-width: 950px) {
	label.keyLabel {
		height: calc(2em + 1vw);
		width: calc(2em + 1vw);
		line-height: 3.2;
		margin: 0.2em;
	}
}

input:checked + label.keyLabel {
	background-image: -webkit-radial-gradient(#00c0ff 0%, #e5dede 92%);
	background-image: radial-gradient(#00c0ff 0%, #e5dede 92%);
}

input {
	position: absolute;
  left: -1000px;
}

.keyContainerWrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-bottom: 1.4em;
}
@media (min-width: 950px) {
	.keyContainerWrapper {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
}
.sharpFlatContainer {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.notationWrapper {
	width: 140px;
	height: 30px;
}

/* @media {
	margin: 1em;

} */

label.notationLabel {
	background-image: -webkit-linear-gradient(top, #00c0ff 0%, #0085b1 80%);
	background-image: linear-gradient(to bottom, #00c0ff 0%, #0085b1 80%);
	display: inline-block;
	cursor: pointer;
	border-radius: 25px;
	width: calc(6em + 4vw);
	line-height: calc(1em + 1vw);
	height: calc(1em + 1vw);
}

input:checked + label.notationLabel {
	background-image: -webkit-radial-gradient(#00c0ff 0%, #e5dede 127%);
	background-image: radial-gradient(#00c0ff 0%, #e5dede 127%);
}

.notationContainerWrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin-left: 1em;
	margin-right: 1em;
}

@media (min-width: 950px) {
	.notationContainerWrapper {
		width: calc(6em + 20vw);
	}
}
.chartWrapper {
	--primary-color: red;
	position: relative;
	display: inline-block;
	width: 100%;
	background-color: pink;
	-webkit-align-self: flex-start;
	        align-self: flex-start;
}
@media (min-width: 950px) {
	.chartWrapper {
		width: 80vw;
	}
}


.overlayImage {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left:	0;
	width: 100%;
}
.modalOuterContainer {
	position: absolute;
	-webkit-align-self: flex-end;
	        align-self: flex-end;
	top: 20px;
	right: 20px;
}

.openModalButton {
	background: whitesmoke;
	height: 3em;
	border: medium none currentColor;
	border: initial;
	border-radius: 6px;
	font-weight: 700;
	cursor: pointer;
}

.closeModalButton {
	float: right;
	font-size: 62px;
	cursor: pointer;
}

/* * Overlay */
.ReactModal__Overlay {
	opacity: 0;
	-webkit-transition: opacity 1000ms ease-in-out;
	transition: opacity 1000ms ease-in-out;
	height: 100%;
	width: 100%;
	overflow: scroll;

}

.ReactModal__Overlay--after-open{
	opacity: 1;
}

.ReactModal__Overlay--before-close{
	opacity: 0;
}

/* * Content */
.ReadModal__Content {
	text-align: center;
	overflow-x: hidden;
}

.modalInput {
	background-color: red;
	text-align: center;
}

/* disallow scrolling on body when modal is up */
.ReactModal__Body--open {
	overflow: hidden;
}

.modal-content h1 {
	text-align: center;
}

.modal-content__image {
	width: 90%;
	margin: 0 auto;
}
body {
  background-color: #282c34;
  /* background-image: linear-gradient(black, #283134, #00b4ff, lightblue, #00b4ff, #283134, black); */
  /* background-image: linear-gradient(#0b0b0b, #802900, #ff5100, #ffadad, #ff5100, #802900, #0b0b0b); */
}

.App {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  min-height: 100vh;
  text-align: center;
}
.App-header {
  color: whitesmoke;
  text-shadow: 6px 4px 4px black;
  font-size: calc(.5em + 2vmin);
  margin-bottom: 1.4em;
}
.mainContent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* align-self: flex-start; */
  width: 100%;
  padding-top: 3vh;
}
@media (min-width: 950px) {
  .mainContent {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }
}

.selection {
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
}

input:focus {
  outline: none;
}

/* footer {
  margin-top: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	color: whitesmoke;
	background-image: linear-gradient(to bottom left, #00c0ff, #1b67da);
	height: 40px;
	width: 100%;
} */
