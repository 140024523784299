.notationWrapper {
	width: 140px;
	height: 30px;
}

/* @media {
	margin: 1em;

} */

label.notationLabel {
	background-image: linear-gradient(to bottom, #00c0ff 0%, #0085b1 80%);
	display: inline-block;
	cursor: pointer;
	border-radius: 25px;
	width: calc(6em + 4vw);
	line-height: calc(1em + 1vw);
	height: calc(1em + 1vw);
}

input:checked + label.notationLabel {
	background-image: radial-gradient(#00c0ff 0%, #e5dede 127%);
}
