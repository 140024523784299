.chartWrapper {
	--primary-color: red;
	position: relative;
	display: inline-block;
	width: 100%;
	background-color: pink;
	align-self: flex-start;
}
@media (min-width: 950px) {
	.chartWrapper {
		width: 80vw;
	}
}


.overlayImage {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left:	0;
	width: 100%;
}