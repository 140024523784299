.notationContainerWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: 1em;
	margin-right: 1em;
}

@media (min-width: 950px) {
	.notationContainerWrapper {
		width: calc(6em + 20vw);
	}
}